<template>
  <!-- begin::MyVehicle tabs for SenderAdmin & SenderEmployee Roles -->
  <div v-if="isAuthenticated">
    <div class="d-flex justify-content-between">
      <div class="manager-title mb-3">
        <div>
          {{ $t("MY_VEHICLES.VEHICLE") }}
          <span v-if="VehicleData"> - {{ VehicleData.Plate }}</span>
          <hr class="manager-title-sub" />
        </div>
      </div>

      <div v-if="ShowPriceSearchButton">
        <button
          class="btn btn-pill button-filter btn-tertiary"
          @click="startNewPriceSearchByVehicle"
        >
          <inline-svg src="/media/icons/tarifas.svg" class="mr-3" />
          {{ $t("PRICE_SEARCH.GO_TO_SEARCH_2") }}
        </button>
      </div>
    </div>

    <v-tabs v-model="ActiveTab" align-with-title class="mb-3 mx-0">
      <v-tabs-slider color="#61bb0c"></v-tabs-slider>

      <v-tab grow v-for="t in TabList" :key="t.Position" :href="'#' + t.Name">
        {{ t.Title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items class="mx-0" :value="ActiveTab">
      <v-tab-item v-for="t in TabList" :key="t.Position" :value="t.Name">
        <div v-if="t.Name === 'history'">
          <MyVehiclesHistory :vehicle-id="VehicleID" />
        </div>

        <div v-else>
          <MyVehiclesEdit
            :vehicle-id="VehicleID"
            @change-vehicle-active="VehicleData.Active = $event"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
  <!-- end::MyVehicle tabs for SenderAdmin & SenderEmployee Roles -->
</template>

<script>
import { mapGetters } from "vuex";

import PriceSearchService from "@/core/services/api/v2/pricesearch.service";
import VehicleService from "@/core/services/api/v2/vehicle.service";

import MyVehiclesHistory from "@/view/pages/manager/vehicles/sender/History.vue";
import MyVehiclesEdit from "@/view/pages/manager/vehicles/sender/Edit.vue";

export default {
  name: "MyVehicleTabs",
  components: {
    MyVehiclesHistory,
    MyVehiclesEdit
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ActiveTab: {
      set(ActiveTab) {
        this.$router.replace({ query: { ...this.$route.query, ActiveTab } });
      },
      get() {
        return this.$route.query.ActiveTab;
      }
    },
    ShowPriceSearchButton() {
      return (
        this.VehicleData &&
        this.VehicleData.Active &&
        !this.VehicleData.Travelling
      );
    }
  },
  data() {
    return {
      VehicleID: 0,
      VehicleData: null,
      TabList: [
        {
          Position: 0,
          Name: "history",
          Title: this.$i18n.t("MY_VEHICLES.SUBTITLE_HISTORY")
        },
        {
          Position: 1,
          Name: "profile",
          Title: this.$i18n.t("MY_VEHICLES.TITLE_EDIT")
        }
      ]
    };
  },
  mounted() {
    // Pick vehicle data from its ID
    if (this.$route.params.id) {
      this.VehicleID = parseInt(this.$route.params.id);
      this.loadVehicleData();
    } else {
      this.VehicleID = -1;
    }
  },
  methods: {
    loadVehicleData() {
      VehicleService.getProfile(this.VehicleID).then(response => {
        if (response === 404) {
          this.$router.push("/manager/myvehicles");
        } else {
          this.VehicleData = response;
        }
      });
    },

    startNewPriceSearchByVehicle() {
      PriceSearchService.cleanCachedLists();
      this.$router.push("/pricesearch/step1?VehicleID=" + this.VehicleID);
    }
  }
};
</script>
