<template>
  <!-- begin::VehicleModel step-by-step search -->
  <div class="py-0 my-0">
    <v-form class="form">
      <div class="row py-0">
        <div class="form-group" :class="FieldColClass">
          <label>
            {{ $t("VEHICLE_MODELS.BRAND") }}
          </label>
          <select
            class="form-control select-down"
            v-model="Fields.Brand"
            required
            :rules="[rules.required]"
            @change="onChangeBrand"
          >
            <option
              v-for="(brandName, index) in ModelBrandOptions"
              :key="index"
              :value="brandName"
              class="text-primary"
            >
              {{ brandName }}
            </option>
          </select>
          <div
            v-if="TriggerValidatePicker && !Fields.Brand"
            class="v-text-field__details pt-2 pl-5 pb-0 m-0"
          >
            <div class="v-messages theme--light error--text" role="alert">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">
                  {{ $t("VALIDATION.REQUIRED_FIELD") }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" :class="FieldColClass">
          <label>
            {{ $t("VEHICLE_MODELS.MODEL") }}
          </label>
          <select
            class="form-control select-down"
            v-model="Fields.Model"
            required
            :rules="[rules.required]"
            @change="onChangeModel"
          >
            <option
              v-for="(modelName, index) in ModelModelOptions"
              :key="index"
              :value="modelName"
              class="text-primary"
            >
              {{ modelName }}
            </option>
          </select>
          <div
            v-if="TriggerValidatePicker && !Fields.Model"
            class="v-text-field__details pt-2 pl-5 pb-0 m-0"
          >
            <div class="v-messages theme--light error--text" role="alert">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">
                  {{ $t("VALIDATION.REQUIRED_FIELD") }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" :class="FieldColClass">
          <label>
            {{ $t("VEHICLE_MODELS.VERSION") }}
          </label>
          <select
            class="form-control select-down"
            v-model="Fields.Version"
            required
            :rules="[rules.required]"
            @change="onChangeVersion"
          >
            <option
              v-for="(item, index) in ModelVersionOptions"
              :key="index"
              :value="item.Version"
              class="text-primary"
            >
              {{ item.Version }}
            </option>
          </select>
          <div
            v-if="TriggerValidatePicker && !Fields.Version"
            class="v-text-field__details pt-2 pl-5 pb-0 m-0"
          >
            <div class="v-messages theme--light error--text" role="alert">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">
                  {{ $t("VALIDATION.REQUIRED_FIELD") }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="ShowYearField" class="form-group" :class="FieldColClass">
          <label>
            {{ $t("VEHICLE_MODELS.YEAR") }}
          </label>
          <select
            class="form-control select-down"
            v-model="Fields.Year"
            required
            :rules="[rules.required]"
            @change="onChangeYear"
          >
            <option
              v-for="item in ModelYearOptions"
              :key="item"
              :value="item"
              class="text-primary"
            >
              {{ item }}
            </option>
          </select>
          <div
            v-if="TriggerValidatePicker && !Fields.Year"
            class="v-text-field__details pt-2 pl-5 pb-0 m-0"
          >
            <div class="v-messages theme--light error--text" role="alert">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">
                  {{ $t("VALIDATION.REQUIRED_FIELD") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="ShowTypeId || ShowModelId" class="row">
        <div v-if="ShowTypeId" class="form-group" :class="FieldColClass">
          <label>
            <inline-svg src="/media/icons/car-primary.svg" class="mr-3" />
            {{ $t("VEHICLE_MODELS.VEHICLE_TYPE") }}
          </label>
          <h4 class="mt-2">
            {{ getVehicleTypeName(Fields.VehicleTypeID) }}
          </h4>
        </div>

        <div v-if="ShowModelId" class="form-group py-0" :class="FieldColClass">
          <label>
            {{ $t("VEHICLE_MODELS.MODEL_ID") }}
          </label>
          <h4 class="mt-2">
            {{ Fields.VehicleModelID }}
          </h4>
        </div>
      </div>
    </v-form>
  </div>
  <!-- end::VehicleModel step-by-step search -->
</template>

<script>
import VehicleModelService from "@/core/services/api/v2/vehiclemodel.service";
import VehicleTypeService from "@/core/services/api/v2/vehicletype.service";

export default {
  name: "VehicleModelPicker",
  props: {
    InitialModel: {
      type: Object,
      required: false,
      default() {
        return {
          VehicleModelID: 0,
          VehicleType: null,
          Brand: "",
          Model: "",
          Version: "",
          Year: ""
        };
      }
    },
    ShowModelId: {
      type: Boolean,
      required: false,
      default: false
    },
    ShowTypeId: {
      type: Boolean,
      required: false,
      default: false
    },
    ShowYearField: {
      type: Boolean,
      required: false,
      default: true
    },
    TriggerValidatePicker: {
      type: Boolean,
      required: false,
      default: true
    },
    FieldsPerRow: {
      type: Number,
      required: false,
      default: 4
    }
  },

  data() {
    return {
      VehicleTypeNames: [],
      ModelBrandOptions: [],
      ModelModelOptions: [],
      ModelVersionOptions: [],
      ModelYearOptions: [],
      FieldColClass: "col-lg-12",
      Fields: {
        VehicleModelID: "",
        VehicleTypeID: "",
        Brand: "",
        Model: "",
        Version: "",
        Year: ""
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD")
      }
    };
  },

  created() {
    this.loadVehicleTypeNames();
    this.loadModelBrandOptions();
  },

  mounted() {
    this.FieldColClass =
      this.FieldsPerRow === 4
        ? "col-lg-3 py-3"
        : this.FieldsPerRow === 2
        ? "col-lg-6 py-2"
        : "col-lg-12 py-1";

    if (
      !this.InitialModel ||
      this.InitialModel.VehicleModelID !== this.Fields.VehicleModelID
    ) {
      Object.assign(this.Fields, {
        Brand: this.InitialModel ? this.InitialModel.Brand : "",
        Model: this.InitialModel ? this.InitialModel.Model : "",
        Version: this.InitialModel ? this.InitialModel.Version : "",
        Year: this.InitialModel ? this.InitialModel.Year : "",
        VehicleModelID: this.InitialModel
          ? this.InitialModel.VehicleModelID
          : "",
        VehicleTypeID:
          this.InitialModel && this.InitialModel.VehicleType
            ? this.InitialModel.VehicleType.VehicleTypeID
            : ""
      });

      this.loadModelBrandOptions();
    }
  },

  watch: {
    InitialModel() {
      if (
        !this.InitialModel ||
        this.InitialModel.VehicleModelID !== this.Fields.VehicleModelID
      ) {
        Object.assign(this.Fields, {
          Brand: this.InitialModel ? this.InitialModel.Brand : "",
          Model: this.InitialModel ? this.InitialModel.Model : "",
          Version: this.InitialModel ? this.InitialModel.Version : "",
          Year: this.InitialModel ? this.InitialModel.Year : "",
          VehicleModelID: this.InitialModel
            ? this.InitialModel.VehicleModelID
            : "",
          VehicleTypeID:
            this.InitialModel && this.InitialModel.VehicleType
              ? this.InitialModel.VehicleType.VehicleTypeID
              : ""
        });

        this.loadModelBrandOptions();
      }
    }
  },

  methods: {
    loadVehicleTypeNames() {
      this.VehicleTypeNames = [];

      VehicleTypeService.listAll().then(response => {
        response.forEach(vt => {
          this.VehicleTypeNames[vt.VehicleTypeID] =
            vt.Position + ": " + vt.Name;
        });
      });
    },

    getVehicleTypeName(vtID) {
      let name = this.VehicleTypeNames[vtID];
      if (!name) {
        name = " - ";
      }

      return name;
    },

    loadModelBrandOptions() {
      this.ModelBrandOptions = [];

      VehicleModelService.listAllBrands().then(response => {
        this.ModelBrandOptions = response;
        this.loadModelModelOptions();
      });
    },

    onChangeBrand() {
      Object.assign(this.Fields, {
        Model: "",
        Version: "",
        Year: "",
        VehicleTypeID: "",
        VehicleModelID: ""
      });

      this.loadModelModelOptions();
    },

    loadModelModelOptions() {
      this.ModelModelOptions = [];
      this.ModelVersionOptions = [];
      this.ModelYearOptions = [];

      if (this.Fields.Brand) {
        VehicleModelService.listAllModelsByBrand(this.Fields.Brand).then(
          response => {
            this.ModelModelOptions = response;

            /*
            if (!this.Fields.Model || this.Fields.Model.length === 0) {
              this.Fields.Model =
                  this.ModelModelOptions.length > 0
                    ? this.ModelModelOptions[0]
                    : "";
            }
            */

            this.loadModelVersionOptions();
          }
        );
      }
    },

    onChangeModel() {
      Object.assign(this.Fields, {
        Version: "",
        Year: "",
        VehicleTypeID: "",
        VehicleModelID: ""
      });

      this.loadModelVersionOptions();
    },

    loadModelVersionOptions() {
      this.ModelVersionOptions = [];
      this.ModelYearOptions = [];

      if (this.Fields.Brand && this.Fields.Model) {
        VehicleModelService.search(
          "",
          this.Fields.Brand,
          this.Fields.Model,
          "",
          "",
          "Version",
          0,
          999,
          0
        ).then(rawResponse => {
          this.ModelVersionOptions =
            rawResponse === 404 ? [] : rawResponse.response.VehicleModels;

          /*
          if (!this.Fields.Version || this.Fields.Version.length === 0) {
            this.Fields.Version =
                this.ModelVersionOptions.length > 0
                  ? this.ModelVersionOptions[0].Version
                  : "";
          }
          */

          this.onChangeVersion();
        });
      }
    },

    onChangeVersion() {
      this.Fields.VehicleTypeID = "";
      this.Fields.VehicleModelID = "";

      this.ModelYearOptions = [];

      for (let v = 0; v < this.ModelVersionOptions.length; v++) {
        let item = this.ModelVersionOptions[v];
        if (item.Version === this.Fields.Version) {
          this.Fields.VehicleTypeID = item.VehicleTypeID;
          this.Fields.VehicleModelID = item.VehicleModelID;

          for (let y = item.YearFrom; y <= item.YearTo; y++) {
            this.ModelYearOptions.push(y);
          }

          break;
        }
      }

      this.$emit("change-model-id", parseInt(this.Fields.VehicleModelID));
    },

    onChangeYear() {
      this.$emit("change-model-data", this.Fields);
    }
  }
};
</script>
