<template>
  <!-- begin::MyVehicles edit -->
  <div>
    <div v-if="VehicleId < 1" class="manager-title">
      <div>
        {{ $t("MY_VEHICLES.TITLE_ADD") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box">
      <v-form ref="formBasic" v-model="Valid" lazy-validation class="form">
        <div class="manager-subtitle">
          {{ $t("MY_VEHICLES.SUBTITLE_DATA") }}
        </div>

        <div class="row">
          <div class="form-group col-lg-6">
            <label>
              {{ $t("MY_VEHICLES.PLATE") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Plate"
              :placeholder="$t('MY_VEHICLES.PLATE')"
              required
              :rules="[rules.required]"
            />
          </div>
        </div>

        <VehicleModelPicker
          :initial-model.sync="InitialVehicleModelData"
          :show-type-id="true"
          :show-model-id="false"
          :fields-per-row="2"
          :trigger-validate-picker.sync="TriggerValidatePicker"
          @change-model-data="onChangeVehicleModelPicker"
        />

        <div class="row">
          <div class="form-group col-lg-3">
            <label>
              {{ $t("MY_VEHICLES.VEHICLE_STATUS") }}
            </label>
            <select
              class="form-control select-down"
              v-model="Fields.VehicleStatusID"
              required
              :rules="[rules.required]"
            >
              <option
                v-for="vs in VehicleStatusOptions"
                :key="vs.VehicleStatusID"
                :value="vs.VehicleStatusID"
                class="text-primary"
              >
                {{ vs.Name }}
              </option>
            </select>
          </div>

          <div class="form-group col-lg-3">
            <label>
              {{ $t("MY_VEHICLES.COLOR") }}
              <span class="font-weight-normal">
                ({{ $t("GENERIC.OPTIONAL") }})
              </span>
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Color"
              :placeholder="$t('MY_VEHICLES.COLOR')"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-9">
            <label>
              {{ $t("MY_VEHICLES.NOTES") }}
              <span class="font-weight-normal">
                ({{ $t("GENERIC.OPTIONAL") }})
              </span>
            </label>
            <textarea
              rows="3"
              class="form-control"
              v-model="Fields.Notes"
              :placeholder="$t('MY_VEHICLES.NOTES')"
            ></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 form-group mb-0">
            <v-checkbox v-model="Fields.Active" @click="onChangeActive">
              <template v-slot:label>
                <div class="pt-1 ml-3 text-dark font-weight-normal">
                  {{ $t("MY_VEHICLES.ACTIVE_INFO") }}
                </div>
              </template>
            </v-checkbox>
          </div>
        </div>
      </v-form>

      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="$router.push('/manager/myvehicles')"
        >
          <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          class="btn btn-pill btn-tertiary button-form"
          :disabled="!Valid || !VehicleModelPickerValid"
          @click="onSubmitForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
    </div>
  </div>
  <!-- end::MyVehicles edit -->
</template>

<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import VehicleService from "@/core/services/api/v2/vehicle.service";
import VehicleStatusService from "@/core/services/api/v2/vehiclestatus.service";

import VehicleModelPicker from "@/view/pages/manager/vehicles/VehicleModelPicker.vue";

export default {
  name: "MyVehiclesEdit",
  components: {
    VehicleModelPicker
  },

  props: {
    VehicleId: {
      type: Number,
      required: false,
      default: -1
    }
  },

  data() {
    return {
      VehicleStatusOptions: [],
      InitialVehicleModelData: null,
      TriggerValidatePicker: false,
      Valid: true,
      Fields: {
        Plate: "",
        Year: 0,
        VehicleModelID: 0,
        VehicleStatusID: 0,
        Color: "",
        Notes: "",
        Active: ""
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD")
      }
    };
  },

  computed: {
    VehicleModelPickerValid() {
      return parseInt(this.Fields.VehicleModelID) > 0;
    }
  },

  mounted() {
    // Show page loader
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    this.loadVehicleStatusOptions();
  },

  methods: {
    loadVehicleStatusOptions() {
      this.VehicleStatusOptions = [];
      VehicleStatusService.listAll().then(response => {
        this.VehicleStatusOptions = response;
        this.loadVehicleData();
      });
    },

    loadVehicleData() {
      if (this.VehicleId < 1) {
        // New vehicle
        this.Fields.VehicleStatusID = this.VehicleStatusOptions[0].VehicleStatusID;

        this.InitialVehicleModelData = {
          VehicleModelID: 0,
          VehicleType: null,
          Brand: "",
          Model: "",
          Version: "",
          Year: ""
        };

        // Remove page loader
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      } else {
        // Edit vehicle
        VehicleService.getProfile(this.VehicleId).then(response => {
          if (response === 404) {
            this.$router.push("/manager/myvehicles");
          } else {
            // Pick fields to be edited on this form
            for (var f1 in this.Fields) {
              this.Fields[f1] = response[f1];
            }

            this.Fields.VehicleModelID = response.VehicleModel.VehicleModelID;
            this.Fields.VehicleStatusID =
              response.VehicleStatus.VehicleStatusID;

            // Pick fields to be edited on VehicleModelPicker
            response.VehicleModel.Year = response.Year;
            this.InitialVehicleModelData = response.VehicleModel;

            // Remove page loader
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          }
        });
      }
    },

    onChangeActive() {
      this.$emit("change-vehicle-active", this.Fields.Active);
    },

    onChangeVehicleModelPicker(newData) {
      this.Fields.Year = newData.Year;
      this.Fields.VehicleModelID = newData.VehicleModelID;
    },

    onSubmitForm(e) {
      e.preventDefault();

      // First, validate sub-form of VehicleModelPicker
      this.TriggerValidatePicker = true;

      if (this.VehicleModelPickerValid && this.$refs.formBasic.validate()) {
        // Disable submit button
        this.Valid = false;

        // Send request:
        if (this.VehicleId > 0) {
          // Update Vehicle
          VehicleService.updateProfile(this.VehicleId, this.Fields).then(
            statusCode => {
              if (statusCode >= 400) {
                let whyError = this.$i18n.t("GENERIC.TRY_LATER");
                if (statusCode === 409) {
                  whyError = this.$i18n.t("MY_VEHICLES.ERROR_409");
                } else if (statusCode === 400) {
                  whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
                }

                this.$bvToast.toast(whyError, {
                  title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                  variant: "danger",
                  solid: true
                });
              } else {
                this.$bvToast.toast(" ", {
                  title: this.$i18n.t("GENERIC.SAVE_OK"),
                  variant: "success",
                  solid: true
                });
              }

              // Reset submit button
              this.Valid = true;
            }
          );
        } else {
          // Create new Vehicle: we get back its ID
          VehicleService.createProfile(this.Fields).then(response => {
            if (response.VehicleID) {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });

              this.VehicleID = response.VehicleID;
              setTimeout(
                () =>
                  this.$router.push(
                    "/manager/myvehicles/edit/" +
                      response.VehicleID +
                      "?ActiveTab=profile"
                  ),
                2000
              );
            } else {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (response === 409) {
                whyError = this.$i18n.t("MY_VEHICLES.ERROR_409");
              } else if (response === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          });
        }
      }
    }
  }
};
</script>
