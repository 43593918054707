<template>
  <!-- begin::MyVehicles history -->
  <div class="white-box shadow-box">
    <div class="row mt-9 mb-6 font-size-h5 text-primary">
      <div class="col-lg-6 pl-6">
        <inline-svg src="/media/icons/pin-1-primary.svg" class="mr-3" />
        <strong>{{ $t("MY_VEHICLES.ADDRESS") }}</strong>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/icons/calendar-alt-1-primary.svg"
          class="mr-3"
        />
        <strong>{{ $t("MY_VEHICLES.DATE") }}</strong>
      </div>
      <div class="col-lg-3">
        <strong>{{ $t("MY_VEHICLES.IN_OUT") }}</strong>
      </div>
    </div>

    <div v-if="ItemsSearching" class="text-primary pl-3">
      {{ $t("GENERIC.SEARCHING") }}...
    </div>

    <div
      v-if="!ItemsSearching && AddressHistory.length < 1"
      class="text-danger pl-3"
    >
      {{ $t("GENERIC.NO_RESULTS") }}
    </div>

    <div
      v-for="(item, index) in AddressHistory"
      :key="index"
      class="row py-6 white-table border-box shadow-box mb-6"
    >
      <div class="col-lg-6">
        <strong>{{ item.TradeName }}</strong>
        <br />
        {{
          item.Street +
            " - " +
            item.PostalCode +
            " " +
            item.City +
            " (" +
            getProvinceName(item.ProvinceID) +
            ")"
        }}
      </div>
      <div class="col-lg-3">
        {{ formatDatetime(item.DoneAt) }}
      </div>
      <div class="col-lg-3">
        {{ $t("MY_VEHICLES.IN_OUT_" + item.InOut) }}
      </div>
    </div>

    <div class="row mt-12 mb-6">
      <button
        class="btn btn-pill btn-white button-form button-form-primary mr-9"
        @click="$router.push('/manager/myvehicles')"
      >
        <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
        {{ $t("GENERIC.BACK") }}
      </button>
    </div>
  </div>
  <!-- end::MyVehicles history -->
</template>

<script>
import moment from "moment";

import ProvinceService from "@/core/services/api/v2/province.service";
import VehicleService from "@/core/services/api/v2/vehicle.service";

export default {
  name: "MyVehiclesHistory",
  props: {
    VehicleId: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      ItemsSearching: true,
      ProvinceNames: [],
      AddressHistory: []
    };
  },

  mounted() {
    this.loadProvinceNames();
  },

  methods: {
    loadProvinceNames() {
      this.ProvinceNames = [];

      ProvinceService.listAll().then(response => {
        response.forEach(p => {
          this.ProvinceNames[p.ProvinceID] = p.Name;
        });

        this.loadVehicleHistory();
      });
    },

    loadVehicleHistory() {
      this.AddressHistory = [];

      VehicleService.getAddressHistory(this.VehicleId).then(response => {
        if (response === 404) {
          this.$router.push("/manager/myvehicles");
        } else {
          this.ItemsSearching = false;
          this.AddressHistory = response.AddressHistory;
        }
      });
    },

    getProvinceName(pID) {
      let name = this.ProvinceNames[pID];
      if (!name) {
        name = " - ";
      }

      return name;
    },

    formatDatetime(datetime) {
      if (!datetime || datetime.lenght < 10) {
        return "-";
      } else {
        return moment(datetime).format("DD/MM/YYYY - HH:mm");
      }
    }
  }
};
</script>
